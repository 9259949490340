import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PlaygroundDemo from "./components/playgroundDemo";
import './App.scss'
import Loader from "./components/Loader";

const App = () => {
  const isLoading = useSelector((state) => state.user.isLoading);

  function LoadingPage() {
    return <Loader isLoading={isLoading} />;
  }

  function InvalidUrl() {
    return (
      <div className="App">
        <p>Invalid url</p>
      </div>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <Suspense fallback={<LoadingPage />}>
            <Routes>
              <Route
                path="/:accountId/:demoId"
                exact
                element={<PlaygroundDemo />}
              ></Route>
               <Route
                path="/:linkId"
                exact
                element={<PlaygroundDemo />}
              ></Route>
                <Route path='/' exact element={<InvalidUrl />}></Route>
            </Routes>
          </Suspense>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
