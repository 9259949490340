import React from "react";
import './Toast.scss';

const Toast = ({ type, message }) => {
  return (
    <div className={`toast ${type}`}>
      {type === "success" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
        >
          <path
            d="M18.8337 9.73745V10.5041C18.8326 12.3011 18.2507 14.0497 17.1748 15.489C16.0988 16.9283 14.5864 17.9812 12.8631 18.4907C11.1399 19.0002 9.29804 18.939 7.61238 18.3163C5.92673 17.6935 4.48754 16.5425 3.50946 15.035C2.53138 13.5275 2.06682 11.7442 2.18506 9.95105C2.30329 8.15792 2.998 6.45106 4.16556 5.08501C5.33312 3.71897 6.91098 2.76694 8.66382 2.37091C10.4167 1.97488 12.2505 2.15607 13.892 2.88745M18.8337 3.83268L10.5003 12.1743L8.00033 9.67435"
            stroke="#12B76A"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : type === "error" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            d="M10.0001 8.00019V11.3335M10.0001 14.6669H10.0085M8.84622 3.74329L1.99215 15.5821C1.61198 16.2388 1.42189 16.5671 1.44999 16.8366C1.47449 17.0716 1.59763 17.2852 1.78876 17.4242C2.0079 17.5835 2.38728 17.5835 3.14605 17.5835H16.8542C17.613 17.5835 17.9923 17.5835 18.2115 17.4242C18.4026 17.2852 18.5258 17.0716 18.5503 16.8366C18.5783 16.5671 18.3883 16.2388 18.0081 15.5821L11.154 3.74329C10.7752 3.08899 10.5858 2.76184 10.3387 2.65196C10.1232 2.55612 9.87709 2.55612 9.66154 2.65196C9.41443 2.76184 9.22503 3.08899 8.84622 3.74329Z"
            stroke="#F04438"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : type === "info" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            d="M10.0003 13.8327V10.4993M10.0003 7.16602H10.0087M18.3337 10.4993C18.3337 15.1017 14.6027 18.8327 10.0003 18.8327C5.39795 18.8327 1.66699 15.1017 1.66699 10.4993C1.66699 5.89698 5.39795 2.16602 10.0003 2.16602C14.6027 2.16602 18.3337 5.89698 18.3337 10.4993Z"
            stroke="#9E77ED"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        ""
      )}
      <span style={{marginLeft: '8px'}}>{message}</span>
    </div>
  );
};

export default Toast;
