// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast {
  position: fixed;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 4px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.07px;
}
.toast.success {
  background: #D1FADF;
  color: #05603A;
}
.toast.error {
  background: #FEE4E2;
  color: #912018;
}
.toast.info {
  background: #F4EBFF;
  color: #53389E;
}`, "",{"version":3,"sources":["webpack://./src/components/toast/Toast.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;EACA,2BAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AACJ;AAAI;EACI,mBAAA;EACA,cAAA;AAER;AAAI;EACI,mBAAA;EACA,cAAA;AAER;AAAI;EACI,mBAAA;EACA,cAAA;AAER","sourcesContent":[".toast{\n    position: fixed;\n    display: flex;\n    align-items: center;\n    padding: 12px 16px;\n    border-radius: 4px;\n    top: 30px;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: 9999;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 21px;\n    letter-spacing: 0.07px;\n    &.success{\n        background: #D1FADF;\n        color: #05603A;\n    }\n    &.error{\n        background: #FEE4E2;\n        color: #912018;\n    }\n    &.info{\n        background: #F4EBFF;\n        color: #53389E;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
