// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.target-area-container:hover {
  cursor: pointer;
}

.highlight-connection {
  animation: blink-animation 0.6s 1;
  border-radius: 8px;
  border: 1px solid #0038FF;
  background: rgba(0, 56, 255, 0.12);
  opacity: 0;
}

@keyframes blink-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/targetArea.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;;AAGA;EACI,iCAAA;EACA,kBAAA;EACA,yBAAA;EACA,kCAAA;EACA,UAAA;AAAJ;;AAGE;EACE;IACE,UAAA;EAAJ;EAEE;IACE,UAAA;EAAJ;EAEE;IACE,UAAA;EAAJ;AACF","sourcesContent":[".target-area-container{\n  &:hover{\n    cursor: pointer;\n  }\n}\n.highlight-connection {\n    animation: blink-animation 0.6s 1;\n    border-radius: 8px;\n    border: 1px solid #0038FF;\n    background: rgba(0, 56, 255, 0.12);  \n    opacity: 0;  \n  }\n  \n  @keyframes blink-animation {\n    0% {\n      opacity: 0;\n    }\n    50% {\n      opacity: 1;\n    }\n    100% {\n      opacity: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
