import React from 'react'
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  z-index: 99999;
  border-top-width: 5px;
  border-left-width: 5px;
  border-right-width: 5px;
  border-bottom-width: 5px;
  `;

const Loader = ({isLoading, mobileView}) => {
  return (
    <div style={{position: mobileView ? 'absolute' :'fixed', top: '50%', left:'50%', transform: 'translate(-50% , -50%)', zIndex: 99999}}>
    <ClipLoader color="#EF6F53" css={override} loading={isLoading} size={50} />
    </div>
  )
}

export default Loader
