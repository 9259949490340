import "./targetArea.scss";
export function TargetAreaPreview({
  connection,
  handleClick,
  highlightConnection,
}) {

  return (
    <div
      id="target-area-normal"
      className={`target-area-container ${
        highlightConnection ? "highlight-connection" : ""
      }`}
      onClick={() => handleClick(connection)}
      style={{
        top: `${connection?.image_target_area.top}%`,
        left: `${connection?.image_target_area.left}%`,
        width: `${connection?.image_target_area.width}%`,
        height: `${connection?.image_target_area.height}%`,
        position: "absolute",
      }}
    ></div>
  );
}
