const initialState = {
  playgroundDemo: null,
  isLoading: null,
  errorMessage: "",
  successMessage: "",
  widgetDemo: null,
  showPasswordModal: false,
  isDemoLoading: false,
};

export default function fetchReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_PLAYGROUND_DEMO":
      return {
        ...state,
        showPasswordModal: false,
        playgroundDemo: action.payload.demo,
        sessionId: action.payload.session_id,
      };
    case "SET_WIDGET_DEMO":
      return {
        ...state,
        widgetDemo: action.payload.widgetDemo,
        sessionId: action.payload.session_id,
      };
    case "ENABLE_LOADER":
      return { ...state, isLoading: true };
    case "DISABLE_LOADER":
      return { ...state, isLoading: false };
    case "ENABLE_DEMO_LOADER":
      return { ...state, isDemoLoading: true };
    case "DISABLE_DEMO_LOADER":
      return { ...state, isDemoLoading: false };
    case "SET_ERROR":
      return { ...state, errorMessage: action.payload };
    case "HIDE_ERROR":
      return { ...state, errorMessage: action.payload };
    case "SHOW_PASSWORD_MODAL":
      return { ...state, showPasswordModal: action.payload };
    default:
      return state;
  }
}
