import userAPI from './userAPI'

const config = {
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
}

const passconfig = (password) => (
    {
	headers: { 'Content-Type': 'application/json', 'X-Rivia-Internal-Passcode': password },
	withCredentials: true
})

export const enableLoader = () => {
	return dispatch => {
		dispatch({type: 'ENABLE_LOADER', payload: true})
	}
}

export const disableLoader = () => {
	return dispatch => {
		dispatch({type: 'DISABLE_LOADER', payload: false})
	}
}

export const enableDemoLoader = () => {
	return dispatch => {
		dispatch({type: 'ENABLE_DEMO_LOADER'})
	}
}

export const disableDemoLoader = () => {
	return dispatch => {
		dispatch({type: 'DISABLE_DEMO_LOADER'})
	}
}

export const hideErrorMessage = () => {
	return dispatch => {
		dispatch({type: 'HIDE_ERROR', payload: ''})
	}
}

export const fetchPlaygroundDemo = ({accountId, demoId, linkId, password}) => {
    return async dispatch => {
        dispatch(enableLoader());
        try {
            let response
            if(accountId && demoId){
            response = await userAPI.get(`/publish/opt/${accountId}/${demoId}?type=playground`, config)
            }else if(password && linkId){
            response = await userAPI.get(`/publish/opt/${linkId}?type=playground`, passconfig(password))
            }
            else if(linkId){
            response = await userAPI.get(`/publish/opt/${linkId}?type=playground`, config)
            }
            const demoResponse = await userAPI.get(response.data.demo_path)
            dispatch({type: "SET_PLAYGROUND_DEMO", payload: {demo: demoResponse.data.demo, session_id: response.data.session_id}})
            const widgetDemoId = demoResponse?.data?.demo?.widget_demo_id
            if(widgetDemoId){
                const response = await userAPI.get(`/publish/opt/${accountId}/${widgetDemoId}?type=playground`)
                const demoResponse = await userAPI(response.data.demo_path)
                dispatch({type: "SET_WIDGET_DEMO", payload: {widgetDemo: demoResponse.data.demo, session_id: response.data.session_id}})
            }
        } catch (error) {
            if(error.response.data.message === 'password not provided'){
                dispatch({type: 'SHOW_PASSWORD_MODAL', payload: true})
            }else{
            dispatch({type: 'SET_ERROR', payload: error.response.data.message})
            }
        }
        dispatch(disableLoader());
    }
}

export const setPasswordProtected = (payload) => {
    return dispatch => {
    dispatch({type:'SET_PASSWORD_PROTECTED', payload})
    }
}


