import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  disableDemoLoader,
  disableLoader,
  enableDemoLoader,
  enableLoader,
  fetchLink,
  fetchPlaygroundDemo,
  hideErrorMessage,
  setshowPasswordModal,
} from "../app/action";
import { useParams } from "react-router";
import Loader from "./Loader";
import "./playgroundDemo.scss";
import iphoneFrame from "../static/iphoneFrame.svg";
import { TargetAreaPreview } from "./targetArea";
import colorDots from "../static/colordots.svg"
import { Tooltip } from 'antd'
import mobileView from '../static/mobileView.svg'
import passwordIcon from '../static/password-icon.svg'
import Toast from "./toast/Toast";

const playgroundDemo = () => {
  const dispatch = useDispatch();
  const { accountId, demoId, linkId } = useParams();
  const isLoading = useSelector((state) => state.user.isLoading);
  const errorMessage = useSelector((state) => state.user.errorMessage);
  const successMessage = useSelector((state) => state.user.successMessage);
  const playgroundDemo = useSelector((state) => state.user.playgroundDemo);
  const [currentCapture, setCurrentCapture] = useState(null);
  const captureId = useRef(null)
  const [verticalFit, setVerticalFit] = useState();
  const [showImage, setShowImage] = useState(false)
  const [imageclicksWithoutDestination, setImageClicksWithoutDestination] = useState(1)
  const [highlightConnection, setHighlightConnection] = useState(false)
  const widgetDemo = useSelector(state => state.user.widgetDemo)
  const [widgetCurrentCapture, setWidgetCurrentCapture] = useState(null)
  const [isMobileViewOpen, setIsMobileViewOpen] = useState(false)
  const showPasswordModal = useSelector(state => state.user.showPasswordModal)
  const [password, setPassword] = useState("")
  const isDemoLoading = useSelector(state => state.user.isDemoLoading)

  useEffect(() => {
    dispatch(enableDemoLoader())
    if(accountId && demoId){
    dispatch(fetchPlaygroundDemo({accountId, demoId}));
    }else if(linkId){
      dispatch(fetchPlaygroundDemo({linkId}))
    }
  }, []);

  useEffect(() => {
    const errorTimeout = setTimeout(() => dispatch(hideErrorMessage()), 4000);

    return () => clearTimeout(errorTimeout);
}, [errorMessage, dispatch]);

// useEffect(() => {
//     const successTimeout = setTimeout(() => dispatch(hideSuccessMessage()), 4000);

//     return () => clearTimeout(successTimeout);
// }, [successMessage, dispatch]);


// Set current capture based on playground demo data
  useEffect(() => {
    if (playgroundDemo) {
      let masterCaptureId = playgroundDemo.master_capture_id;
      setCurrentCapture(playgroundDemo.captures.find((capture) => 
      capture.capture_id === masterCaptureId));
      captureId.current = playgroundDemo.captures.find((capture) => 
      capture.capture_id === masterCaptureId).capture_id;
    }
  }, [playgroundDemo, captureId]);

  useEffect(() => {
    if(widgetDemo){
      let masterCaptureId = widgetDemo.master_capture_id;
      setWidgetCurrentCapture(widgetDemo?.captures.find((capture) => 
      capture.capture_id === masterCaptureId))
    }
  },[widgetDemo])


  useEffect(() => {
    if(currentCapture?.capture_type === "IMAGE"){
      dispatch(enableDemoLoader())
    const img = new Image();
    img.src = currentCapture?.image_url;

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      
      if(width < height) {
        setVerticalFit(true)
      }else {
        setVerticalFit(false)
      }
      setShowImage(true)
    };
  }
  setShowImage(false)
    
}, [currentCapture?.capture_id]);

  // Add event listener for incoming messages from iframe
  useEffect(() => {
    if (captureId.current) {
      window.addEventListener("message", handleIncomingMessages);
      return () => {
        window.removeEventListener("message", handleIncomingMessages);
      };
    }
  }, [captureId.current]);

  const handleIncomingMessages = (event) => {
    var data = event.data;
    if (!data) {
      return;
    }
    if (typeof data === "string") {
      data = JSON.parse(data);
    }
    if (data.messageType === "EVENT" && data.message === "connection") {
      GotoDesitinationCapture(
        data.elementId,
        data?.parentElementIds,
        data.inputValue,
        data.iframeSrc,
        data.xpathId,
        data?.parentElementIdsByXpath,
      );
    }
  };

  let clicksWithoutDestination = 0;
  let clickTimeout;

  const resetClicks = () => {
    clicksWithoutDestination = 0;
    clickTimeout = null;
  };

  // Function to navigate to a destination capture

  const GotoDesitinationCapture = (elementId, parentElementIds, inputValue, iframeSrc, xpathId, parentElementIdsByXpath) => {
    const iframe = document.getElementById('demo-preview-iframe')

      if(iframe.src !== iframeSrc){
        captureId.current = iframeSrc.split('/')[5];
      }
    
    let destinationConnection;
    let negativeConnection;

    if (elementId) {
      for (const id of parentElementIds) {
        destinationConnection = playgroundDemo?.connections.find(connection =>
          connection.src_rivia_id === id
        );
      
        if (destinationConnection) {
          break;
        }
      }
    }

    if (!destinationConnection && parentElementIdsByXpath) {
      for (const id of parentElementIdsByXpath) {
        destinationConnection = playgroundDemo?.connections.find(connection =>
          connection.src_rivia_xpath_id === id
        );
  
        if (destinationConnection) {
          break;
        }
      }
    }

      let elementsWithNegativeInteraction = playgroundDemo.connections.filter(
        (element) =>
          element.hasOwnProperty("negative_interaction") &&
          element.negative_interaction === true
      );

      negativeConnection = elementsWithNegativeInteraction.find(
        (connection) => connection.src_capture_id === captureId.current && !parentElementIds.includes(connection.src_rivia_id)
      );

      if (!negativeConnection && xpathId && parentElementIdsByXpath) {
        negativeConnection = elementsWithNegativeInteraction.find(
          (connection) => connection.src_capture_id === captureId.current && !parentElementIdsByXpath.includes(connection.src_rivia_xpath_id)
        );
      }

      // Logic to navigate to a destination capture based on input
      if (destinationConnection?.text_input?.length) {
        clicksWithoutDestination = 0;
        if (destinationConnection.text_input === inputValue) {
          if(destinationConnection?.redirect_url){
            window.open(`${destinationConnection.redirect_url}`, "_blank")
          }else{
          const dstCapture = playgroundDemo?.captures.find(
            (capture) =>
              capture.capture_id === destinationConnection?.dst_capture_id
          );
            dispatch(enableDemoLoader());
            iframe.src = dstCapture?.html_url
            captureId.current = dstCapture?.capture_id;
        }
      }
      } else if (destinationConnection) {
        // Logic to navigate to destination on click of an element
        clicksWithoutDestination = 0;

        const isNegativeInteraction =
          destinationConnection.negative_interaction;

        if (!isNegativeInteraction) {
          if(destinationConnection?.redirect_url){
            window.open(`${destinationConnection.redirect_url}`, "_blank")
          }else{
          const dstCapture = playgroundDemo?.captures.find(
            (capture) =>
              capture.capture_id === destinationConnection?.dst_capture_id
          );
            if(dstCapture?.capture_id !== captureId.current){
            dispatch(enableDemoLoader());
            iframe.src = dstCapture?.html_url
            captureId.current = dstCapture?.capture_id
            }
        }
      }
      } 
      // Logic to navigate to destination if there is a negative interaction
      else if (
        negativeConnection
      ) {
        clicksWithoutDestination = 0;
        if(negativeConnection?.redirect_url){
          window.open(`${negativeConnection.redirect_url}`, "_blank")
        }else{
        const dstCapture = playgroundDemo.captures.find(
          (capture) => capture.capture_id === negativeConnection.dst_capture_id
        );
          dispatch(enableDemoLoader());
          iframe.src = dstCapture?.html_url
          captureId.current = dstCapture?.capture_id;
        }
      } else {
        if(!inputValue){
        clicksWithoutDestination++;
        }
        if (!clickTimeout) {
          // Set timeout to reset clicks without destination after 3 seconds
          clickTimeout = setTimeout(resetClicks, 3000);
        }
        if (clicksWithoutDestination > 2) {
          if (document.getElementById("demo-preview-iframe")) {
            document
              .getElementById("demo-preview-iframe")
              .contentWindow.postMessage(
                JSON.stringify({
                  action: "HIGHLIGHT_ELEMENTS",
                  elementId: elementId,
                  elementIdsWithConnection: captureId.current.startsWith('cap')
                  ? playgroundDemo?.connections?.filter(connection => connection.src_capture_id === captureId.current)?.map(connection => connection.src_rivia_id)
                  : playgroundDemo?.connections?.map(connection => connection.src_rivia_id),
                  xpathIdsWithConnection: captureId.current.startsWith('cap')
                  ? playgroundDemo?.connections?.filter(connection => connection.src_capture_id === captureId.current)?.map(connection => connection?.src_rivia_xpath_id)
                  : playgroundDemo?.connections?.map(connection => connection?.src_rivia_xpath_id),
                  
                }),
                "*"
              );
          }
          resetClicks()
        }
      }
  };

  document.title = showPasswordModal ? "Please enter your password" :playgroundDemo?.title;

  const handleTargetClick = (connection) => {
    let dstCapture;
    dispatch(enableDemoLoader())
    if(connection?.demo_id === playgroundDemo?.demo_id){
    dstCapture = playgroundDemo.captures.find ((capture) => capture.capture_id === connection.dst_capture_id)
    setCurrentCapture(dstCapture)
    }else{
    dstCapture = widgetDemo.captures.find ((capture) => capture.capture_id === connection.dst_capture_id)
    setWidgetCurrentCapture(dstCapture)
  }
  setImageClicksWithoutDestination(1)
  }


  const handleImageClicked = () =>{
   setImageClicksWithoutDestination(prevState => prevState +1 )
   setTimeout(() => {
      setImageClicksWithoutDestination(1);
    }, 3000);
   if(imageclicksWithoutDestination > 2){
    setHighlightConnection(true)
    setImageClicksWithoutDestination(1)
    setTimeout(() => {
      setHighlightConnection(false)
    }, 700);
   }else{
    setHighlightConnection(false)
   }
  }

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    dispatch(fetchPlaygroundDemo({linkId, password}))
}

  return (
    <div className="demo-main-container">
      {!isMobileViewOpen && <Loader isLoading={isLoading || isDemoLoading} />}
      {showPasswordModal && 
            <>
            <div className="modal-container">
               <img src={passwordIcon} alt="password" />
               <div style={{display:'flex', flexDirection:'column', gap:'8px', alignItems:'center'}}>
               <span style={{fontSize:'18px', fontWeight: 600}}>Password Required</span>
               <span style={{fontSize:'14px', color: '#667085'}}>You need password to acesss the link</span>
               </div>
               <form  onSubmit={handlePasswordSubmit}>
               <label>Password</label>
               <input type="password" placeholder="Enter password" onChange={(e) => setPassword(e.target.value)} required />
               <button className="continue-button">Continue</button>
               </form>
            </div>
            </>
            }
      {errorMessage && <Toast message={errorMessage} type="error" />}
      {successMessage && <Toast message={successMessage} type="success" />}
      {currentCapture?.capture_type === 'HTML' ?
        <>
        <iframe
        id="demo-preview-iframe"
        src={currentCapture?.html_url}
        style={{ width: "100%", height: "100%" }}
        onLoad={() => dispatch(disableDemoLoader())}
      />{
        widgetDemo && 
        <Tooltip color="#101828" placement="left" title="Open Mobile View">
        <img className="mobile-view-button" src={mobileView} onClick={() => setIsMobileViewOpen(!isMobileViewOpen)} />
        </Tooltip>
      }
        {isMobileViewOpen && (      
        <div className="widget-pseudo-container">
        <Loader mobileView = {isMobileViewOpen} isLoading={isLoading} />
        <img src={iphoneFrame} className="iphone-image" />
        <div className="image-container">
        <img id= "playground-preview-image" onClick={handleImageClicked} className="widget-preview-mobile" src={widgetCurrentCapture?.image_url} onLoad={() => dispatch(disableDemoLoader())} />
        {widgetDemo?.connections?.filter(connection => connection.src_capture_id === widgetCurrentCapture?.capture_id)?.map(connection => (
        <TargetAreaPreview key={connection.connection_id} highlightConnection={highlightConnection} connection= {connection} handleClick={handleTargetClick}/>
        ))
        }
        </div>
        </div>
        )}
        </>
       : currentCapture?.capture_type === 'IMAGE' && (
          verticalFit ? (
        <div className="image-pseudo-container">
        <div className="image-container">
        <img src={iphoneFrame} className="iphone-image" />
        <div style={{position: 'absolute', width:'300px', height:'637px', top:'16px', left:'16px'}}>
        <img id= "playground-preview-image" onClick={handleImageClicked} className="playground-preview-mobile" src={currentCapture?.image_url} onLoad={() => dispatch(disableDemoLoader())}/>
        {playgroundDemo?.connections?.filter(connection => connection.src_capture_id === currentCapture.capture_id)?.map(connection => (
        <TargetAreaPreview highlightConnection={highlightConnection} connection= {connection} handleClick={handleTargetClick}/>
        ))
        }
        </div>
        </div>
        </div>
      ):(
        <div className="image-pseudo-container">
        {showImage && <div className="image-container">
        <div className="browser-toolbar">
        <img src={colorDots} />
        <div className="url-tab">
        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
        <div style={{margin:"auto", alignItems:"center", display:"flex"}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
        <path d="M7.2928 14.6666H11.5971C12.2886 14.6666 12.6555 14.286 12.6555 13.5344V10.2568C12.6555 9.50518 12.2886 9.12937 11.5971 9.12937H7.2928C6.6013 9.12937 6.23438 9.50518 6.23438 10.2568V13.5344C6.23438 14.286 6.6013 14.6666 7.2928 14.6666ZM7.05759 9.49566H7.80555V7.88778C7.80555 6.68901 8.56291 6.05157 9.44258 6.05157C10.3223 6.05157 11.089 6.68901 11.089 7.88778V9.49566H11.8323V7.99244C11.8323 6.20379 10.6751 5.33325 9.44258 5.33325C8.21481 5.33325 7.05759 6.20379 7.05759 7.99244V9.49566Z" fill="#8C8C8C"/>
        </svg>
        <span>{playgroundDemo?.title}</span>
        </div>
        <Tooltip color="#101828" placement="bottom" title="Restart demo">
        <svg style={{cursor:"pointer"}} onClick={() => window.location.reload()} xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
        <path d="M5.5957 10.8301C5.5957 12.9586 7.28471 14.6666 9.38524 14.6666C11.4858 14.6666 13.1705 12.9586 13.1705 10.8301C13.1705 10.622 13.0247 10.4703 12.8147 10.4703C12.6132 10.4703 12.4803 10.622 12.4803 10.8301C12.4803 12.5728 11.1042 13.9686 9.38524 13.9686C7.66623 13.9686 6.28588 12.5728 6.28588 10.8301C6.28588 9.09173 7.66623 7.70018 9.38524 7.70018C9.71104 7.70018 10.0154 7.72619 10.2683 7.78688L8.98657 9.07005C8.92226 9.13941 8.88797 9.22611 8.88797 9.31715C8.88797 9.51656 9.03372 9.66395 9.22663 9.66395C9.3338 9.66395 9.41525 9.62927 9.47526 9.56425L11.2414 7.76954C11.3186 7.69585 11.3486 7.60914 11.3486 7.50944C11.3486 7.41407 11.31 7.3187 11.2414 7.24934L9.47526 5.43729C9.41525 5.36793 9.32951 5.33325 9.22663 5.33325C9.03372 5.33325 8.88797 5.48931 8.88797 5.68872C8.88797 5.77976 8.92226 5.86646 8.98228 5.93582L10.1226 7.07594C9.89966 7.03258 9.64674 7.00224 9.38524 7.00224C7.28471 7.00224 5.5957 8.70591 5.5957 10.8301Z" fill="#8C8C8C"/>
        </svg>
        </Tooltip>
        </div>
        </div>
        </div>
        <img className="playground-preview-desktop" onClick={handleImageClicked} src={currentCapture?.image_url} onLoad={() => dispatch(disableDemoLoader())}/>
        <div>
        {playgroundDemo?.connections?.filter(connection => connection.src_capture_id === currentCapture.capture_id)?.map(connection => (
        <TargetAreaPreview connection= {connection} handleClick={handleTargetClick} highlightConnection={highlightConnection}/>
        ))
        }
        </div>
        </div>
        }
        </div>
      ))} 
    </div>
  );
};

export default playgroundDemo;
